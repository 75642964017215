import React from 'react';
import { shape, string, bool, number, arrayOf } from 'prop-types';

import { ALL_ITEMS_IMAGES } from '../../../../../../utils/constants';
import { ItemImage } from './ItemImage';

const CardMultipleItems = ({ items, namespace, moreItems }) => {
  // Extra Validation in case the items provided are more than the limit
  const itemsLength = items.length <= ALL_ITEMS_IMAGES ? items.length : ALL_ITEMS_IMAGES;

  if (itemsLength === 3) {
    return (
      <div
        className={`${namespace}__${itemsLength}-items-container`}
        aria-hidden="true"
      >
        { /** Card Left Side */ }
        <ItemImage
          item={items[0]}
          className={`${namespace}__items-images-container ${namespace}__${itemsLength}-items-container-left`}
        />

        { /** Card Right Side */ }
        <div className={`${namespace}__${itemsLength}-items-container-right`}>
          {items.slice(1, 3).map((item) => (
            <ItemImage
              key={item.picture.id}
              item={item}
              className={`${namespace}__${itemsLength}-items-container-right-item`}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${namespace}__items-images ${namespace}__items-images--${itemsLength}`}
      aria-hidden="true"
    >
      {items.slice(0, 4).map((item, index) => (index !== ALL_ITEMS_IMAGES - 1 ? (
        <ItemImage
          key={item.picture.id}
          item={item}
          className={`${namespace}__items-images-container ${namespace}__items-images-container-${itemsLength}`}
        />
      ) : (
        <ItemImage
          key={item.picture.id}
          item={item}
          className={`${namespace}__items-images--last-image ${
            moreItems ? `${namespace}__items-images--additional_items` : ''
          }`}
          namespace={namespace}
          moreItems={moreItems}
        />
      )))}
    </div>
  );
};

CardMultipleItems.propTypes = {
  items: arrayOf(
    shape({
      free_installments: bool,
      id: string,
      permalink: string,
      picture: shape({
        url: shape({
          src: string,
        }),
      }),
      price: shape({
        current_symbol: string,
        original_value: number,
        value: number,
      }),
      title: string,
      track: shape({
        c_element_order: number,
        c_id: string,
      }),
    }),
  ),
  namespace: string,
  moreItems: shape({
    label: shape({
      text: string,
      color: string,
      under_line: bool,
    }),
    target: string,
  }),
};

export default CardMultipleItems;
