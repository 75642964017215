import React from 'react';
import { Image } from 'nordic/image';
import { shape, string, bool, number } from 'prop-types';

import { LAZYLOAD_OFF } from '../../../../../../utils/constants';

export const ItemImage = ({ item, className, namespace, moreItems }) => (
  <div className={className}>
    <Image
      src={item.picture.url.src}
      alt={item.picture.url.alt}
      width="100%"
      height="100%"
      lazyload={LAZYLOAD_OFF}
    />
    {moreItems?.label?.text && (
      <span className={`${namespace}__items-images__label`}>
        {moreItems.label.text}
      </span>
    )}
  </div>
);

ItemImage.propTypes = {
  item: shape({
    free_installments: bool,
    id: string,
    permalink: string,
    picture: shape({
      url: shape({
        src: string,
      }),
    }),
    price: shape({
      current_symbol: string,
      original_value: number,
      value: number,
    }),
    title: string,
    track: shape({
      c_element_order: number,
      c_id: string,
    }),
  }),
  className: string,
  namespace: string,
  moreItems: shape({
    label: shape({
      text: string,
      color: string,
      under_line: bool,
    }),
    target: string,
  }),
};
