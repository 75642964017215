import React from 'react';
import classnames from 'classnames';
import { shape, string, bool, number, arrayOf } from 'prop-types';

import { Card as AndesCard } from '@andes/card';
import { Button } from '@andes/button';

import withTracker from '../../with-tracker';
import withTrackerOnPrint from '../../with-tracker-on-print';

import CardSingleItem from './cards/CardSingleItem';
import CardMultipleItems from './cards/CardMultipleItems';

const namespace = 'repurchase-widget';

const Widget = ({ card, type }) => {
  const { header, items, primary_action, more_items } = card;

  if (items && items.length > 0) {
    const isSingleItem = items.length === 1;
    const isMobile = type === 'mobile';

    return (
      <AndesCard className={classnames('repurchase-widget-card', namespace)}>
        {header?.title?.text && (
          <div className={`${namespace}-header`}>
            <div className={`${namespace}__title`}>
              <h2>{header.title.text}</h2>
            </div>

            {primary_action && !isMobile && (
              <div className={`${namespace}__subtitle`}>
                <a
                  className={`${namespace}__subtitle-link`}
                  href={primary_action.target}
                  aria-label={primary_action.label?.accessibility_text}
                >
                  {primary_action.label?.text}
                </a>
              </div>
            )}
          </div>
        )}

        {isSingleItem ? (
          <CardSingleItem
            data={items[0]}
            namespace={namespace}
            isMobile={isMobile}
          />
        ) : (
          <CardMultipleItems
            items={items}
            namespace={namespace}
            moreItems={more_items}
          />
        )}

        {primary_action && isMobile && (
          <Button
            hierarchy="loud"
            size="large"
            href={primary_action.target}
            className={`${namespace}__action`}
            srLabel={primary_action.label?.accessibility_text}
          >
            {primary_action.label?.text}
          </Button>
        )}
      </AndesCard>
    );
  }

  return null;
};

Widget.propTypes = {
  card: shape({
    header: shape({
      title: shape({
        color: string,
        text: string,
        under_line: bool,
      }),
    }),
    primary_action: shape({
      label: shape({
        text: string,
        bg_color: string,
        color: string,
        under_line: bool,
      }),
      target: string,
    }),
    items: arrayOf(
      shape({
        free_installments: bool,
        id: string,
        permalink: string,
        picture: shape({
          alt: string,
          src: string,
        }),
        price: shape({
          current_symbol: string,
          original_value: number,
          value: number,
        }),
        title: string,
        track: shape({
          c_element_order: number,
          c_id: string,
        }),
      }),
    ),
    more_items: shape({
      label: shape({
        text: string,
        color: string,
        under_line: bool,
      }),
      target: string,
    }),
    track: shape({
      c_id: string,
    }),
  }),
  type: string,
};

export default withTracker(withTrackerOnPrint(Widget));
