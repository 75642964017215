import React from 'react';
import { string, object } from 'prop-types';
import Widget from './components/widget';

const RepurchaseWidget = ({ widgetProps, type }) => (
  <Widget card={widgetProps} type={type} />
);
RepurchaseWidget.propTypes = {
  widgetProps: object,
  type: string,
};

export default RepurchaseWidget;
