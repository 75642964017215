import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import { MoneyAmountCombo } from '@andes/money-amount';
import classnames from 'classnames';
import { Image } from 'nordic/image';

import { LAZYLOAD_OFF } from '../../../../../../utils/constants';
import modelMoneyAmountCombo from '../../../dynamic-access/utils/model-money-amount-combo';

const CardSingleItem = ({ data, namespace, isMobile }) => {
  const { picture, price, title } = data;
  const { original_price, current_value, discount } = price;

  return (
    <div className={`${namespace}__one-item-container`}>
      {picture?.url?.src && (
        <div
          className={classnames(`${namespace}__image`, {
            [`${namespace}__image--${picture.url.format}`]: picture.url.format,
          })}
          aria-hidden="true"
        >
          <Image
            src={picture.url.src}
            width="100%"
            height="100%"
            alt={picture.url.alt}
            lazyload={LAZYLOAD_OFF}
          />
        </div>
      )}
      <div className={classnames(`${namespace}__item-description`)}>
        {title && <p className={`${namespace}__item-title`}>{title}</p>}
        {current_value && (
          <MoneyAmountCombo
            previousValue={modelMoneyAmountCombo(
              original_price,
              isMobile ? 12 : 16,
            )}
            currentValue={modelMoneyAmountCombo(
              current_value,
              isMobile ? 20 : 32,
            )}
            discount={discount && discount.value > 0 && { ...discount, size: isMobile ? 12 : 18 }}
            className={`${namespace}__price`}
          />
        )}
      </div>
    </div>
  );
};

CardSingleItem.propTypes = {
  data: shape({
    free_installments: bool,
    id: string,
    permalink: string,
    picture: shape({
      url: shape({
        src: string,
      }),
    }),
    price: shape({
      current_symbol: string,
      original_value: number,
      value: number,
    }),
    title: string,
    track: shape({
      c_element_order: number,
      c_id: string,
    }),
  }),
  namespace: string,
  isMobile: bool,
};

export default CardSingleItem;
